// Enumeradores

export type estatus =
  | 'ATIVO'
  | 'INATIVO'
  | 'VENCIDO'
  | 'PENDING_CODE_CONFIRMATION' //aguardando a confirmação de Acesso através do código enviado por email
  | 'PENDING_NEW_PASSWORD' //aguardando a troca da nova Senha através da URL enviada
  | 'PENDING_ADMIN_ACTIVATION' //aguardando o Admin liberar e ativar a conta
  | 'PENDING_MANAGER_ACTIVATION' //aguardando o Manager da empresa liberar/ativar o usuário
  | 'PENDING_CLIENT' //aguardando o cliente aprovar ou negar a requisição
  | 'APROVADO' //quando uma requisição de resgate for aprovada pelo cliente
  | 'NEGADO' //quando uma requisição de resgate for negada pelo cliente
  | 'RESGATE_EFETUADO' //quando, após aprovação, os brindes forem retirados pelo cliente
  | 'SORTEIO_REALIZADO'; //quando o sorteio for realizado

export type enegativa =
  | 'DESISTENCIA'
  | 'NAO_AUTORIZADA'
  | 'PRAZO_EXPIRADO_DESISTENCIA';

export type erole = 'USER' | 'MANAGER' | 'ADMIN';

export type egender = 'INDEFINIDO' | 'MASCULINO' | 'FEMININO';

export type elog_action =
  | 'LOGIN'
  | 'LOGOFF'
  | 'LOGIN_TIMEOUT'
  | 'ACCESS_REQUESTED'
  | 'RESET_PASSWORD'
  | 'READ'
  | 'INSERT'
  | 'UPDATE'
  | 'DELETE'
  | 'PRINT'
  | 'RESGATAR'
  | 'PONTUAR'
  | 'SESSION_EXPIRED';

export type estatus_envio = 'PENDING' | 'COMPLETE';

export type enatureza_operacao = 'DEBITO' | 'CREDITO';

export type etipo_pergunta_questionario =
  | 'UNICA_ESCOLHA' // sim ou não, certo ou errado, isto é, dentre várias opções, apenas uma selecionada
  | 'MULTIPLA_ESCOLHA' // dentre várias, mais de uma pode ser selecionada
  | 'PROBABILIDADE' //min: 0 (0%) - 5 (50%) max: 10 (100%)
  | 'TEXTO_LIVRE'
  | 'MATRIZ'; //relaciona valor (1 a 5) com opções (texto) em forma de grid

export type edispatch_mode = 'MANUAL' | 'AUTOMATICO';

export type erule_type = 'LISTA' | 'EVENTO';

export type idleStatusType = 'NO ACTIVITY' | 'IN ACTIVITY' | 'TIMEOUT';

export type toolbar_type =
  | 'REFRESH'
  | 'NEW'
  | 'EDIT'
  | 'DELETE'
  | 'LIST'
  | 'EXCEL'
  | 'PDF'
  | 'SEARCH';

export type actionButton_type =
  | 'EDIT'
  | 'DELETE'
  | 'VIEW'
  | 'PONTUAR'
  | 'RESGATAR'
  | 'IMPRIMIR_RECIBO'
  | 'SORTEAR'
  | 'PARTICIPANTES_SORTEIO';

export type form2Steps_type = 'STEP1' | 'STEP2';

export type form3Steps_type = 'STEP1' | 'STEP2' | 'STEP3';

export type form4Steps_type = 'STEP1' | 'STEP2' | 'STEP3' | 'STEP4';

export type formMode_type = 'NEW' | 'EDIT' | 'DELETE' | 'VIEW';

export type popupStatus = 'openned' | 'closed';

export type optionSN_type = 'S' | 'N';

export type tipoOperacao_type =
  | 'SALDO_ANTERIOR'
  | 'COMPRA'
  | 'BONUS'
  | 'AJUSTE_A_CREDITO'
  | 'RESGATE'
  | 'VENCIMENTO'
  | 'AJUSTE_A_DEBITO';

export type field_type =
  | 'text'
  | 'niver'
  | 'idade'
  | 'date'
  | 'time'
  | 'datetime'
  | 'mes'
  | 'number'
  | 'percent'
  | 'currency'
  | 'boolean'
  | 'image'
  | 'icon'
  | 'icon2x'
  | 'icon3x'
  | 'html'
  | 'color'
  | 'cnpj'
  | 'cpf'
  | 'genero'
  | 'whatsapp'
  | 'user_role'
  | 'publico_vale_cupom'
  | 'tipo_objetivo_sorteio'
  | 'phone'
  | 'cep'
  | 'status'
  | 'prompt'
  | 'action'
  | 'actions'
  | 'modalidade_cashback'
  | 'ind_frequencia_compras'
  | 'progress_bar'
  | 'yes_no'
  | 'url_link_cadastro'
  | 'url_link_sorteio'
  | 'url_link_area_cliente'
  | 'cliente_cadastrado'
  | 'tag';

export type navBarFormType =
  | 'MY-DATA'
  | 'MY-CUSTOMER-DATA'
  | 'NEW-USER'
  | 'MY-PASSWORD'
  | 'MY-CUSTOMER-PASSWORD'
  | 'SELECT-ACCOUNT'
  | 'SELECT-CUSTOMER-ACCOUNT'
  | 'CONFIG'
  | 'MY-LOGO';

export type emodalidade_cashback = 'PONTOS' | 'VALOR' | 'AMBAS';

export type epublico_alvo =
  | 'ANIVERSARIANTES'
  | 'TOP10'
  | 'DIAS_SEM_COMPRAR_30'
  | 'MAIS_DE_2_COMPRAS';

export type etransaction_group =
  | 'DASHBOARD'
  | 'CLIENTES'
  | 'RELACIONAMENTO'
  | 'CAMPANHAS'
  | 'FIDELIDADE'
  | 'CONFIGURACAO'
  | 'ADMINISTRACAO'
  | 'OUTRO';

export type ePermissionType = 'read' | 'insert' | 'update' | 'delete';

export const descModalidadeCashback: Record<string, string> = {
  PONTOS: 'Por Pontos',
  VALOR: 'Por Valor',
  AMBAS: 'Por Pontos e Valor',
};

export type eFieldType =
  | 'text'
  | 'email'
  | 'cpf'
  | 'password'
  | 'number'
  | 'date'
  | 'niver'
  | 'currency'
  | 'boolean'
  | 'pin'
  | 'color'
  | 'textarea';

export type eInputMode =
  | 'none'
  | 'text'
  | 'email'
  | 'password'
  | 'numeric'
  | 'tel'
  | 'url'
  | 'decimal'
  | 'search';

export type eLoggedType = 'user' | 'customer';

export type eLoggedAreaType = 'empresa' | 'cliente';

export type eTipoVisualizacaoDashboard =
  | 'brindes_disponiveis'
  | 'extrato_pontos'
  | 'extrato_cashback'
  | 'ultima_compra'
  | 'amigos_indicados'
  | 'responder_pesquisa'
  | 'requisicao_resgate'
  | 'resgates_efetuados';

export type eCountPendenciasEmpresa = {
  qtdEmpresasAguardandoAprovacao: number;
  qtdUsuariosAguardandoAprovacao: number;
};

export type eCountPendenciasCliente = {
  qtdRequisicaoResgateAguardandoAprovacao: number;
  qtdPesquisaSatisfacaoLiberadaResponder: number;
  pesquisaSatisfacaoLiberadaComCredito: boolean;
  qtdSorteioAberto: number;
  isVencedorSorteio: boolean;
};

export type eLastRequestResgate = {
  requisicao_id: string;
  status: estatus;
  created_at: Date;
  dth_venc_autorizacao: Date;
  dth_aprovacao: Date;
  dth_negacao: Date;
  motivo_negativa: enegativa;
};

export type eTipoOrigemCadastroCliente = 'administracao' | 'clientes';

export type eModoReport = 'extratificado' | 'agrupado';

export type eToken_Status = 'vigente' | 'vencido';

export type eSession_Status = 'ativa' | 'vencida';

export type etipo_objetivo_sorteio =
  | 'TICKET_MEDIO'
  | 'CAPTURAR_CLIENTE'
  | 'ENGAJAR_CLIENTES';

export type efiltro_compras_periodo =
  | 'A_PARTIR_DE_1'
  | 'A_PARTIR_DE_2'
  | 'A_PARTIR_DE_3';

export type eform_destino = 'LINK_CADASTRO' | 'SORTEIO';

export type eform_sorteio =
  | 'CADASTRO_SORTEIO'
  | 'PARTICIPANTES_SORTEIO'
  | 'IMAGEM_PREMIO'
  | 'SORTEAR';

export type eformat_link_cadastro = 'url' | 'subdominio';

export type efield_custom_formfield =
  | 'cpf'
  | 'nome'
  | 'celular'
  | 'sexo'
  | 'dt_nascimento'
  | 'email'
  | 'endereco'
  | 'cpf_participante'
  | 'nome_participante'
  | 'celular_participante'
  | 'genero_participante'
  | 'dt_nascimento_participante'
  | 'email_participante';

export type buttonStyle = 'filled' | 'outline' | 'delete';

export type eorigem_cadastro_cliente =
  | 'CARGA_DADOS'
  | 'AREA_CLIENTE'
  | 'AREA_ADMINISTRACAO'
  | 'LINK_CADASTRO'
  | 'LINK_SORTEIO';

export type CroppedDialogData = {
  image: File;
  filename: string;
  width?: number;
  height?: number;
};

export type CroppedDialogResult = {
  blob?: Blob;
  imageUrl?: string;
  base64?: string;
  filename?: string;
  width?: number;
  height?: number;
};

export enum WS_EVENTS {
  connected_reply = 'connected_reply',
  connected_qtd_reply = 'connected_qtd_reply',
  login_event = 'login_event',
  logoff_event = 'logoff_event',
  login_reply = 'login_reply',
  logged_list_reply = 'logged_list_reply',

  event_message_to_client = 'event_message_to_client', //evento para submeter mensagem para o server
  message_from_server = 'message_from_server', //do lado da aplicação

  event_broadcast_message = 'event_broadcast_message', //evento para submeter mensagem para o server
  broadcast_from_server = 'broadcast_from_server', //do lado da aplicação

  pendencias_admin_reply = 'pendencias_admin_reply',
  pendencias_manager_reply = 'pendencias_manager_reply',
  connect = 'connect',
  disconnect = 'disconnect',
  reconnect = 'reconnect',
  error = 'error',
}

export type eWsStatus = 'CONNECTED' | 'DISCONNECTED';

export interface IKeyValueType {
  varName: string;
  varDescription?: string;
  varContext?: string;
  varValue?: any;
}

export type etipo_texto_sistema = 'SOBRE' | 'POLITICA';